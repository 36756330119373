@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: normal;
  src: local("Merriweather"),
    url("../assets/fonts/MerriweatherSans-Regular.ttf") format("truetype");
}

* {
  box-sizing: border-box;
  font-display: swap;
  font-weight: 300;
  font-family: "Merriweather", sans-serif;
  scroll-behavior: smooth;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-in-from-right {
  opacity: 0;
  transform: translateX(10vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-from-right.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-in-section-no-move {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1200ms ease-out, visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-section-no-move.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.App {
  text-align: center;
  background: #000;
}

.less-wide {
  display: none;
}

@media only screen and (max-width: 767px) {
  .wide-only {
    display: none;
  }
  .less-wide {
    display: inline-block;
  }
}

/* Following guidance from https://css-tricks.com/revisiting-prefers-reduced-motion-the-reduced-motion-media-query/
   with regard to animations */
@media screen and (prefers-reduced-motion: reduce), (update: slow) {
  /* Remove duration for all unknown animation when a user requests a reduced animation experience */
  * {
    animation-duration: var(--animation-duration);
    animation-iteration-count: 1 !important;
    transition-duration: var(--animation-duration);
  }
}
